import { Box, List, Toolbar } from '@mui/material';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { NavigationListType } from "../../types/NavigationListType";

import { useStyles } from '../stylesDesktop';
import LayoutDesktopNavItem from "./LayoutDesktopNavItem";
import packageJson from '../../../../package.json';

type PropTypes = {
  navigationList: NavigationListType[]
  logoUrl: string
}

const LayoutDesktopNav = ({ navigationList, logoUrl }: PropTypes) => {
  const classes = useStyles();

  const version = useMemo(() => {
    switch (window.location.hostname) {
      // live
      case 'dashboard.bte-autoreisezug.de':
      case 'dashboard.nachtexpress.de':
        return `Version: ${packageJson.version}`
      // staging
      case 'dashboard.test.bte-autoreisezug.de':
        return `Stage2: ${packageJson.version}`
      case 'dashboard.staging.bte-autoreisezug.de':
        return `Stage1: ${packageJson.version}`
      // dev
      default:
        return `Dev: ${packageJson.version}`
    }
  }, [])

  return (
    <div style={{ position: 'fixed', width: '128px', minHeight: '100vh', background: '#005286'}}>
      <Toolbar>
        {logoUrl && (
          <Link to={'/'} className={classes.logoLink}>
            <img src={logoUrl} className={classes.logoImage} alt={'Logo'} />
          </Link>
        )}
      </Toolbar>
      <List sx={{height: 'calc(100vh - 86px)', overflow: 'auto'}}>
        {navigationList.map(item => (
          <LayoutDesktopNavItem key={item.link} item={item} />
        ))}
      </List>
      <Box sx={{textAlign: 'center', marginTop: 'auto', fontSize: 13, height: 25, color: '#dce9f5'}}>
        {version}
      </Box>
    </div>
  );
}

export default LayoutDesktopNav;
